import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import NavMenu from './NavMenu/Navmenu';
import OcotilloImg from './Image/OcotilloImg';
import WireframeImg from './Image/WireframeImg';
import Footer from './Footer/Footer';
import { footerData } from '../mock/data';
import { PortfolioProvider } from '../context/context';

function OcotilloApp() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setFooter({ ...footerData });
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <PortfolioProvider value={{ footer }}>
      <NavMenu />
      <div id="ocotillo">
        <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <div className="header">
            <OcotilloImg
              imgStyle={{ objectFit: 'contain' }}
              alt="Ocotillo Cover Photo"
              filename="ocotillo-hero.png"
            />
          </div>
        </Fade>

        <div className="container">
          <div className="overview">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <h1>Ocotillo Resort & Spa</h1>

              <div className="details">
                <div>
                  <h3>timeline</h3>
                  <p>2 Weeks</p>
                </div>

                <div>
                  <h3>role</h3>
                  <p>User Research, Design and Prototyping</p>
                </div>

                <div>
                  <h3>tools</h3>
                  <p>Figma</p>
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Brief</h2>

                <p>
                  As part of one my projects for my <b>Design for User Expereince course at USC</b>,
                  I was challenged to design my very own Hotel App. Part of the project criteria was
                  to include certian features likes horizontal scrolling and overlays. Up to this
                  point we have learned the foundations of the Figma design tool and how conduct
                  user research. My goal for this project was to combine these skills to not only
                  create an oasis that users want to stay at but also create a product that would
                  facilitate the process of booking it. This resulted in the creation of the
                  Ocotillo Resort & Spa.
                </p>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Defining the Problem</h2>

                <p>
                  After conducting several interviews as part of the user research process, I found
                  that current hotels and resorts offer rooms that are mostly identical and provide
                  no unique experience. This in conjunction with the lack of privacy deters users
                  from staying at these hotels.
                  <br />
                  <br />
                  <i>
                    People want to book rooms that provide a unique experience and maintain a good
                    level of privacy outside of the room.
                  </i>
                </p>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>User Research</h2>

                <p>
                  In order to better to understand users experience at other hotels, I conducted a
                  couple of online interviews via zoom. I decided to focus primarily on users that
                  have been to a hotel or resort before and users that have hestitated in booking a
                  trip. I asked questions such as:
                </p>

                <ul>
                  <li>What was your experience like while staying at the previous hotel?</li>
                  <li>
                    If you could change anything about that experience, what would you change?
                  </li>
                  <li>What has kept you from booking a room at a Hotel in the past?</li>
                  <li>
                    What kind of information do you want to know before deciding to book a hotel?
                  </li>
                  <li>What is your process for selecting a hotel and room?</li>
                </ul>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>User Responses</h2>

                <p>
                  So what did these users have to say about their previous experience at other
                  hotels?
                </p>

                <ul>
                  <li>
                    &#34;I have stayed the Hilton a couple of times before. Eventhough I booked
                    different rooms, they all felt the same. It felt like there was difference
                    between the high rated rooms and low rated rooms.&#34;
                  </li>
                  <li>
                    &#34;I like to book rooms at resorts because I want to get away from my busy
                    life and have some nice private alone time with my girlfriend by the pool. But
                    the privacy the hotels that I have booked in the past, starts and ends at the
                    room. Sometimes I do not want to share the pool with the hundreds people that
                    are also staying there. It would be nice to know how many other people will be
                    poolside alongside you.&#34;
                  </li>
                  <li>
                    &#34;I have booked rooms in the past that look nothing like was shown in the
                    pictures. I understand that they take the best pictures to sell the rooms but it
                    is a big let down to arrive at your room only to find out that you could have
                    booked a similiar looking room at a Motel 6.&#34;
                  </li>
                </ul>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Initial Wireframing Process</h2>

                <div className="wireframe-home">
                  <WireframeImg
                    imgStyle={{ objectFit: 'contain' }}
                    alt="Home page wireframe"
                    filename="home-iterations.jpg"
                  />
                </div>

                <p>
                  In order to ensure that users got a good first impression of the Ocotillo, I tried
                  two different variations of the home page. Most of the elements on the home page
                  are the same across the two designs. The biggest difference is that <b>Home A</b>{' '}
                  feature a background with combination of different polygons whereas <b>Home B</b>{' '}
                  would feature a poolside image and slight variation in the logo. I took the
                  liberty to design both home pages in order to get feedback from my users. Normally
                  I would obtain feedback by showing the wireframes themselves, but in this case I
                  felt it neccessary to design the frames since the colors and background image make
                  a huge difference when deciding between the two.
                </p>

                <div className="wireframe-container">
                  <div>
                    <WireframeImg
                      imgStyle={{ objectFit: 'contain' }}
                      alt="Home page wireframe"
                      filename="home-a.png"
                    />
                  </div>

                  <div>
                    <WireframeImg
                      imgStyle={{ objectFit: 'contain' }}
                      alt="Home page wireframe"
                      filename="home-b.png"
                    />
                  </div>
                </div>

                <p>
                  Users preferred <b>Home B</b> since it gave more a visual understanding of the
                  Ocotillo has to offer, whereas <b>Home A</b> did not. Instead discarding{' '}
                  <b>Home A</b> altogether, I decided to resuse some of the components in other
                  pages of the app.
                </p>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Wireframe Flow</h2>
                <p>
                  After selecting the home page, I finalized the wireframes for the other pages
                  keeping in mind the main criteria for the project.
                </p>

                <div className="flow">
                  <WireframeImg
                    imgStyle={{ objectFit: 'contain' }}
                    alt="Wireframe flow"
                    filename="flow.jpg"
                  />
                </div>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Moodboard</h2>
                <p>
                  While working on the high fidelity designs, I drew heavy inspiration from my
                  moodboard. From here I drew inspiration for the logo design and choice of primary
                  and secondary colors.
                </p>

                <div className="moodboard">
                  <WireframeImg
                    imgStyle={{ objectFit: 'contain' }}
                    alt="Wireframe flow"
                    filename="moodboard.png"
                  />
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>High Fidelity Designs</h2>
                <p>
                  While working on the high fidelity designs, I drew heavy inspiration from my
                  moodboard. From here I drew inspiration for the logo design and choice of primary
                  and secondary colors.
                </p>

                <div className="high-fidelity">
                  <WireframeImg
                    imgStyle={{ objectFit: 'contain' }}
                    alt="Wireframe flow"
                    filename="high-fidelity.png"
                  />
                </div>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Interactive Prototype</h2>
                <p>Interactive prototype created in Figma.</p>
                <iframe
                  title="prototype"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
                  width="800"
                  height="450"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FloBFfzLGdBYk0DMvaUUGFX%2FThe-Ocotillo-Resort%3Fnode-id%3D16%253A0%26viewport%3D372%252C-72%252C0.25%26scaling%3Dscale-down&chrome=DOCUMENTATION"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Reflection</h2>
                <p>
                  This was definetly one of the most challenging and fun project I have had in the
                  course since I was required to create something scratch. I found myself struggling
                  in the begining since I wanted to create something that would blow the all the
                  other Hotel apps out of the park. However, time was limited and the list of
                  features I wanted to implement kept growing. I constantly had to remind myself on
                  the main criteria for the project. My main takeaway from this project is that I
                  need to do better job during the user research stage.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <Footer />
    </PortfolioProvider>
  );
}

export default OcotilloApp;
