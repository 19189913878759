import React from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import OcotilloApp from '../components/OcotilloApp';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ocotillo Resort | Roberto Sanchez</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Ocotillo Resort & Spa" />
      </Helmet>

      <OcotilloApp />
    </>
  );
};
